:root {
  // Paddings
  --padding-container-horizontal: 2rem;
  --padding-container-horizontal-large: 6.4rem;
  --padding-container-vertical-top: 58px;
  --padding-container-vertical-bottom: 88px;
  --padding-site-header-vertical: 2rem;
  --padding-site-header-horizontal: 70px;
  --padding-block: 8rem;
  --padding-card-vertical: 17px;
  --padding-card-horizontal: 56px;
  --padding-button-vertical: 12px;
  --padding-button-horizontal: 35px;

  // When navigation transforms to a responsive hamburger menu
  @media (max-width: $width-max-mobile) {
    --padding-site-header-horizontal: 18px;
    --padding-container-horizontal: 16px;
  }
}
