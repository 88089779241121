// Layout widths
$width-max-layout: 100%;
$width-max-article: 1311px;
$width-max-article-content: 643px;
$width-max-article-title: 844px;
$width-grid-base: 1185px;

// This is the breakpoint navigation transforms into mobile menu
// NB! IMPORTANT! Remember to change in js/src/navigation.js:
// const responsivenav = 960;
// If these are not the same, navigation toggles will not work properly.
$width-max-mobile: 960px;
$width-min-desktop: 1200px;

// Breakpoints for containers
$container-desktop: 1200px;
$container-ipad: 770px;
$container-ipad-landscape: 1024px;
$container-macbook-air: 1440px;
$container-mobile: 480px;

// Gutenberg widths
$width-wide: 1200px;
$width-full: 100%;