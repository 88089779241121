/** Product searcher **/
.product-searcher-text-field > div {
    color: #058EC9;
    background-color: white;
}
.product-searcher-mobile-container {
    height: 360px;
    overflow: scroll;
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

#product-searcher-mobile-container-div::-webkit-scrollbar {
    width: 12px;
  }
#product-searcher-mobile-container-div::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #E46254, #E4CD28 34%, #71BA8D 67%, #05A8E9 98%);

  }
#product-searcher-mobile-container-div::-webkit-scrollbar-track {
    background: #333333;
  }

.product-searcher-icon-printer {
    position: relative !important;
    bottom: 3px !important;
}

.product-searcher-icon-box {
    position: relative !important;
    bottom: 5px !important;
}

.product-searcher-icon-document {
    position: relative !important;
    bottom: 5px !important;
}

.formDatePicker > div {
    background-color: white;
    border-radius: 1000px;
    width: 185px;
    height: 39px;
} 

@media screen and (max-width: 1230px) {
    .formDatePicker > div {
        max-width: 144px;
        min-width: 144px;
    } 
}

@media screen and (max-width: 1186px){
    .formDatePicker > div {
        max-width: 280px;
        min-width: 280px;
    }
}

@media screen and (max-width: 900px) {
    .formDatePicker > div {
        width: 100%;
        max-width: 191px;
        min-width: 191px;
    } 
}


/** Item field separator **/
.item-field-separator {
    width: 100%;
    background-color: #C9C9C9; height: 1px; border: 0;
}

/** Item quantity field **/
.item-quantity-field > div {
    background-color: white;
    border-radius: 1000px;
    width: 104px;
    height: 39px;
} 

.item-quantity-field input {
    text-align: center;
}

.item-quantity-field-minus-button {
    position: absolute !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-width: 30px !important;
    display: inline;
    z-index: 2;
    right: 65px;
}

.item-quantity-field-plus-button {
    position: absolute !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-width: 30px !important;
    display: inline;
    z-index: 2;
    left: 65px;
}

@media screen and (max-width: 900px) {
    .item-quantity-field > div {
        width: 100%;
        max-width: 187px;
        min-width: 187px;
    } 

    .item-quantity-field-minus-button {
        right: 145px !important;
    }

    .item-quantity-field-plus-button {
        left: 145px !important;
    }
}


/** Item condition selector */
.item-condition-select {
    border-radius: 1000px;
    width: 104px;
    height: 39px;
    background-color: white;
}

.item-condition-select > div {
    padding-left: 15px !important;
}

@media screen and (max-width: 900px) {
    .item-condition-select {
        width: 100%;
        max-width: 187px;
        min-width: 187px;
    }

    .item-condition-select-feature-ul {
        padding-left: 12px !important;
    }
}

@media screen and (max-width: 400px) {
    .item-condition-select-feature-ul {
        max-width: 130px !important;
    }

}

@media screen and (max-width: 380px) {
    .item-condition-select-dialog > .MuiDialog-container > .MuiPaper-root {
        margin: 16px !important;
    }

    .item-condition-select-feature-ul {
        max-width: 140px !important;
    }

    .item-condition-optional {
        display: block !important;
        margin-left: 0 !important;
    }
}

.item-condition-select-feature-li {
    color: black;
    font-family: 'Lexend', sans-serif;
    font-size: 15px;
    font-weight: 300 !important;
}

.item-condition-select-feature-ul {
    max-width: 190px;
}

.item-condition-select-separator {
    background-color: #D9D9D9; height: 2px; border: 0;
}

.item-condition-select-option-box:hover {
    background-color: #e7e7e7;
}

.item-condition-optional {
    color: #787878 !important;
    font-family: 'Lexend', sans-serif !important;
    font-size: 12px !important;
    margin-left: 8px;
    @media screen and (max-width: 380px) {
        margin-left: 0 !important;
    }
}

.item-condition-select-feature-li::marker {
    font-weight: 300 !important;
}

.item-condition-select-checkbox {
    color: #00A7EE !important;
}


/** Expiration date **/
.expiration-date-subtext-break {
    flex-basis: 100%;
    height: 0;
  }

.c4tv-page-wrapper {
    max-width: 1421px;
}

@media screen and (max-width: 900px){
    .c4tv-page-wrapper {
        padding: 0;
    }
}


@media screen and (max-width: 1186px){
    .added-product-form {
        border-top-right-radius: unset;
        border-bottom-left-radius: inherit;
    }

    .added-product-first-box {
        width: 100%;
    }

    .added-product-image-and-text {
        width: 90%;
    }

    .brand-input {
        width: 220px;
        max-width: 220px;
        min-width: 220px;
    }

   
}

@media screen and (max-width: 900px){

    .brand-input {
        width: 130px;
        max-width: 187px;
        min-width: 187px;
    }
}
