.c4tv {
  &-page {
    &-wrapper {
      margin: 0 auto;
      margin-left: auto;
      margin-right: auto;
      max-width: $width-grid-base;
      padding: var(--padding-container-vertical-top) var(--padding-container-horizontal) var(--padding-container-vertical-bottom);

      // If base grid is larger than Macbook Air screen
      @if $width-grid-base > $container-macbook-air {
        @media (min-width: $container-ipad-landscape) and (max-width: $container-macbook-air + 6.4rem) {
          padding-left: var(--padding-container-horizontal-large);
          padding-right: var(--padding-container-horizontal-large);
        }
      }
    }
  }
  &-image {
    &-container {
      &-remove-button {
        line-height: normal !important;
        border-radius: 8px !important;
        min-width: 32px !important;
        min-height: 32px;
        padding: 6px !important;
      }
    }
  }
  &-quote {
    &-create-steps {
      &-additems {
        &-card {
          width: 100%;
        }
        &-container {
          align-items: flex-start;
          justify-content: flex-start;
        }
        &-item-container {
          width: 100%;
        }
      }
    }
  }
  &-account {
    &-main-page-title {
      margin-top: 66px;
      margin-bottom: 28px;
    }
    &-tabs {
      .MuiTabs-flexContainer {
        justify-content: space-between;
      }
    }
    &-notifications {
      &-messages {
        &-empty-container {
          padding-top: 32px;
          padding-bottom: 32px;
        }
      }
    }
    &-info {
      &-user-details {
        &-name {
          text-transform: capitalize;
          font-size: 18px !important;
        }
      }
    }
  }

}

.selling-flow {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    span {
      @include paragraph-medium();
    }
  }
  li:not(.arrow) {
    img {
      margin-right: 8px;
    }
  }
}